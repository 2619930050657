import { navigate } from "gatsby";
import React, { useEffect } from "react";
import { Seo } from "../../components/seo";
import Stores from "../../components/Stores/Stores";
import Layout from "../../hoc/Layout/Layout";

// markup
const IndexPage = () => {

	useEffect(() => {
		const city = localStorage.getItem('city');
		if (city !== 'linkoping') {
			navigate('/har-finns-vi')
		}
	}, []);


	return (
		<Layout>
			<Seo title="Här finns vi" />
			<Stores />
		</Layout>
	);
};

export default IndexPage;
